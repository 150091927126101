/**
 * @name: 业务合作方管理-业务合作方信息管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 业务合作方管理-业务合作方信息管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { ICooperating, ICooperatingParams, ICooperatingDeviceParams } from './types';
import { IDeviceArm } from '@/apis/device/arm-list/types';

export const cooperatingQueryApi = (params: ICooperatingParams) => api.get<IPageRes<ICooperating[]>>('/admin/agent/queryPartnerPage', params)

export const cooperatingDeviceQueryApi = (params: ICooperatingDeviceParams) => api.get<IPageRes<IDeviceArm[]>>('/admin/agent/queryPartnerDevicePage', params)

export const cooperatingPageApi = (params: ICooperatingParams) => api.get<IPageRes<ICooperating[]>>('/admin/partner/queryByPage', params)

export const cooperatingModifyApi = (data: Partial<ICooperating>) => api.postJ('/admin/partner/modify', data)

export const cooperatingDetailApi = (id: string) => api.get<ICooperating>('/admin/partner/detail/' + id)

export const cooperatingCreateApi = (data: Partial<ICooperating>) => api.postJ('/admin/partner/create', data)

export const cooperatingAgentPageApi = (params: any) => api.get('/admin/partner/queryAgentPartnerDetailByPage', params)

export const cooperatingDevicePageApi = (params: any) => api.get('/admin/partner/queryPartnerDeviceDetailByPage', params)
