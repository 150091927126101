
/**
 * @name: 代理商管理-代理商信息管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 代理商管理-代理商信息管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import Big from 'big.js'
import Qrcode from 'qrcodejs2'
import { WEB_HOST } from '@/config'
import {
  agentQueryApi,
  agentDetailApi,
  agentCreateApi,
  agentModifyApi,
  agentModifyStatusApi,
  agentDeviceListApi
} from '@/apis/agent/list'
import {
  cooperatingQueryApi,
  cooperatingDeviceQueryApi
} from '@/apis/cooperating/list'
import {
  IDeviceArm
} from '@/apis/device/arm-list/types'
import {
  referringListApi,
  referringQueryApi
} from '@/apis/referring/list'
import {
  IReferring
} from '@/apis/referring/list/types'
import {
  IAgent,
  IAgentParams
} from '@/apis/agent/list/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from '@/utils/common'
import {
  checkPhone
} from '@/constants/validators'

@Component({})
export default class agentList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    viewTitle: '代理商详情',
    addTitle: '新增代理商',
    editTitle: '编辑代理商',
    labelWidth: '120px',
    menuWidth: 100,
    column: [
      {
        label: '代理商',
        prop: 'agentNameAndId',
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        search: true,
        placeholder: '代理商编号/名称'
      },
      {
        label: '代理商编号',
        prop: 'id',
        addHide: true,
        editHide: true,
        slot: true,
        align: "left",
        width: 180
      },
      {
        label: '代理商名称',
        prop: 'agentName',
        align: "center",
        overHidden: true,
        maxlength: 20,
        width: 150,
        span: 12,
        rules: [
          {
            required: true,
            message: '代理商名称不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                cb(new Error('代理商名称只能输入中文、字母、数字'))
                return
              }
              cb()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: '负责人',
        prop: 'phoneAndName',
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        search: true,
        placeholder: '负责人/手机号'
      },
      {
        label: '负责人',
        prop: 'personInCharge',
        align: "center",
        width: 150,
        overHidden: true,
        maxlength: 10,
        span: 12,
        rules: [
          {
            required: true,
            message: '负责人名称不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                cb(new Error('负责人名称只能输入中文、字母、数字'))
                return
              }
              cb()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: '手机号',
        prop: 'phone',
        align: "center",
        width: 120,
        maxlength: 11,
        span: 12,
        rules: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ],
        editSlot: true
      },
      {
        label: '银联商户号',
        prop: 'unionPayAccount',
        align: 'center',
        search: true,
        width: 150,
        span: 12,
        rules: [
          {
            required: true,
            message: '银联商户号不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: any, callback: any) => {
              if (!/^[a-zA-Z0-9]+$/.test(value)) {
                return callback(new Error('仅支持数字或字母'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        maxlength: 15
      },
      {
        label: '授权设备数量',
        prop: 'deviceNum',
        align: "center",
        width: 120,
        span: 12,
        rules: [
          {
            required: true,
            message: '授权设备数量不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^\d+$/.test(value) || value == '0' || parseFloat(value) === 0) {
                return cb(new Error('数量只能是正整数'))
              }
              if (parseInt(value) > 9999) {
                return cb(new Error('数量最大值9999'))
              }
              cb()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: '已分配设备数量',
        prop: 'allocationDeviceNum',
        align: "center",
        width: 120,
        addHide: true,
        editHide: true,
        slot: true
      },
      {
        label: '分成比例(%)',
        prop: 'commission',
        align: "center",
        width: 120,
        span: 12,
        slot: true,
        rules: [
          {
            required: true,
            message: '分成比例不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^\d+(\.\d{1,2})?$/.test(value) || value == '0' || parseFloat(value) === 0) {
                return cb(new Error('分成比例格式不正确'))
              }
              if (parseFloat(value) >= 100) {
                return cb(new Error('分成比例不能大于等于100'))
              }
              cb()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: '介绍人数量',
        prop: 'introduceNum',
        align: "center",
        width: 120,
        slot: true,
        addHide: true,
        editHide: true,
        viewSlot: true
      },
      {
        label: '介绍人',
        prop: 'introduceNum',
        align: "center",
        width: 120,
        hide: true,
        viewHide: true,
        span: 24,
        addSlot: true,
        editSlot: true
      },
      {
        label: '业务合作方数量',
        prop: 'partnerNum',
        align: "center",
        width: 120,
        slot: true,
        addHide: true,
        editHide: true,
        viewSlot: true
      },
      {
        label: '状态',
        prop: 'status',
        align: "center",
        width: 120,
        type: 'select',
        dicData: [
          {
            label: '启用',
            value: 1
          },
          {
            label: '禁用',
            value: 2
          }
        ],
        slot: true,
        addHide: true,
        editHide: true,
        search: true
      },
      {
        label: '备注',
        prop: 'remarks',
        type: 'textarea',
        showWordLimit: true,
        align: "center",
        overHidden: true,
        span: 24,
        maxlength: 100
      },
      {
        label: '创建时间',
        prop: 'addTime',
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        type: 'daterange',
        width: 150,
        span: 24
      }
    ]
  }

  referringList: IReferring[] = []

  selectReferringId = ''

  selectReferringObj: Partial<IReferring> = {}

  selectReferringList: IReferring[] = []

  referringLoading = false

  allocationDeviceDialog = false

  referringDialog = false

  referringDataLoading = false

  referringDataList: any = []

  referringDataForm: any = {}

  referringDataQuery: any = {
    page: 1,
    limit: 10,
    agentId: ''
  }

  referringDataTotal = 0

  referringDataOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '序号',
        type: 'index',
        width: 50,
        align: 'center'
      },
      {
        label: '介绍人姓名',
        prop: 'introduceName',
        align: 'center',
        overHidden: true
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        overHidden: true
      },
      {
        label: '介绍人分成比例',
        prop: 'commission',
        align: 'center',
        overHidden: true,
        slot: true
      }
    ]
  }



  cooperatingDialog = false

  cooperatingLoading = false

  cooperatingList: any = []

  cooperatingForm: any = {}

  cooperatingQuery: any = {
    page: 1,
    limit: 10,
    agentId: ''
  }

  cooperatingTotal = 0

  cooperatingOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '业务合作方编号',
        prop: 'id',
        align: 'center'
      },
      {
        label: '业务合作方名称',
        prop: 'partnerName',
        align: 'center',
        overHidden: true
      },
      {
        label: '负责人',
        prop: 'personInCharge',
        align: 'center',
        overHidden: true
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        overHidden: true
      },
      {
        label: '合作设备数量',
        prop: 'deviceNum',
        align: 'center',
        slot: true
      }
    ]
  }


  cooperatingDeviceDialog = false

  cooperatingDeviceLoading = false

  cooperatingDeviceList: any = []

  cooperatingDeviceForm: any = {}

  cooperatingDeviceQuery: any = {
    page: 1,
    limit: 10,
    agentId: '',
    partnerId: ''
  }

  cooperatingDeviceTotal = 0

  cooperatingDeviceOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '设备编号',
        prop: 'deviceSn',
        align: 'center'
      },
      {
        label: '消费单价(元)',
        prop: 'price',
        align: 'center',
      },
      {
        label: '业务合作方分成比例',
        prop: 'commission',
        align: 'center',
        slot: true
      },
      {
        label: '投放地址',
        prop: 'address',
        align: 'center',
        overHidden: true,
        slot: true
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center',
        slot: true
      }
    ]
  }



  deviceDataDialog = false

  deviceDataLoading = false

  deviceDataList: any = []

  deviceDataForm: any = {}

  deviceDataQuery: any = {
    page: 1,
    limit: 10,
    id: ''
  }

  deviceDataTotal = 0

  deviceDataOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '设备编号',
        prop: 'deviceSn',
        align: 'center'
      },
      {
        label: '消费单价(元)',
        prop: 'price',
        align: 'center'
      },
      {
        label: '投放地址',
        prop: 'address',
        align: 'center',
        slot: true
      },
      {
        label: '二维码',
        width: 120,
        prop: 'qrCode',
        align: 'center',
        slot: true
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center',
        slot: true
      },
    ]
  }

  selectAgentId = ''

  getList () {
    this.tableLoading = true

    const query: IAgentParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length === 2) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    agentQueryApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableTotal = e.data.total
        this.tableData = e.data.list
      }
    })
  }

  openDetail (row: IAgent, index: number) {
    agentDetailApi(row.id).then(e => {
      if (e && e.data) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e.data, index)
      }
    })
  }

  referringQuery (query?: string) {
    this.referringLoading = true
    referringListApi(query).then(e => {
      if (e && e.data) {
        const ids = this.selectReferringList.map(item => item.id)
        this.referringList = e.data.filter(item => !ids.includes(item.id))
      }
    }).finally(() => {
      this.referringLoading = false
    })
  }

  referringChange (val: string) {
    if (val) {
      this.selectReferringObj = this.referringList.find(item => item.id === val) as IReferring
    } else {
      this.selectReferringObj = {}
    }
  }

  addReferring () {
    if (!this.selectReferringId || !this.selectReferringObj || !this.selectReferringObj.id) {
      return this.$message.warning('请选择介绍人')
    }
    this.selectReferringList.push(deepCopy(this.selectReferringObj))

    this.selectReferringObj = {}
    this.selectReferringId = ''
    this.referringList = []
    this.referringQuery()
  }

  removeReferring (index: number) {
    this.selectReferringList.splice(index, 1)
    this.selectReferringId = ''
    this.selectReferringObj = {}
    this.referringList = []
    this.referringQuery()
  }

  openAdd () {
    this.referringList = []
    this.selectReferringId = ''
    this.selectReferringObj = {}

    this.selectReferringList = []

    this.referringQuery()

    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }

  async openEdit (row: IAgent, index: number) {
    this.referringList = []
    this.selectReferringId = ''
    this.selectReferringObj = {}

    const listRes = await referringQueryApi({page: 1, limit: 9999, agentId: row.id})
    this.selectReferringList = listRes.data.list || []

    agentDetailApi(row.id).then(async e => {
      if (e && e.data) {
        this.referringQuery()
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e.data, index)
      }
    })
  }

  async beforeRowSave () {
    if (!this.selectReferringList || !this.selectReferringList.length) {
      return true
    }
    let sum = new Big(0)
    for (let i = 0; i < this.selectReferringList.length; i++) {
      const item = this.selectReferringList[i]
      if (!/^\d+(\.\d{1,2})?$/.test(String(item.commission)) || item.commission == 0 || parseFloat(String(item.commission)) >= 100) {
        this.$message.error(`介绍人“${this.selectReferringList[i]['introduceName']}”分成比例格式不正确`)
        return false
      }
      sum = sum.plus(item.commission)
    }
    const maxCommission = new Big(100).minus(this.modelForm.commission)
    if (sum.toNumber() >= maxCommission.toNumber()) {
      this.$message.error('介绍人分成比例之和不能大于等于平台分成比例')
      return false
    }
    return true
  }

  rowSave (form: IAgent, done: Function, loading: Function) {
    const data: Partial<IAgent> = deepCopy(form)
    delete data.addTime
    if (this.selectReferringList) {
      // @ts-ignore
      data.agentIntroduceDtoList = this.selectReferringList.map(item => {
        return {
          introduceId: item.id,
          commission: item.commission
        }
      })
    } else {
      data.agentIntroduceDtoList = []
    }
    delete data.introduceNum
    agentCreateApi(data).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IAgent, done: Function, loading: Function) {
    const data: Partial<IAgent> = deepCopy(form)
    delete data.addTime
    if (this.selectReferringList) {
      // @ts-ignore
      data.agentIntroduceDtoList = this.selectReferringList.map(item => {
        return {
          introduceId: item.id,
          commission: item.commission
        }
      })
    } else {
      data.agentIntroduceDtoList = []
    }
    delete data.introduceNum
    agentModifyApi(data).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  switchStatus (row: IAgent) {
    agentModifyStatusApi(row.id).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  getStatusText(status: number) {
    if (status === 1) {
      return '待分配'
    }
    if (status === 2) {
      return '待激活'
    }
    if (status === 3) {
      return '已激活'
    }
    if (status === 4) {
      return '暂停使用'
    }
  }


  getDeviceDataList () {
    this.deviceDataLoading = true
    agentDeviceListApi(this.deviceDataQuery).then(e => {
      if (e && e.data) {
        this.deviceDataTotal = e.data.total
        this.deviceDataList = e.data.list
        this.deviceDataLoading = false

        setTimeout(() => {
          this.$nextTick(() => {
            for (let i = 0; i < this.deviceDataList.length; i++) {
              const dom = document.querySelector(`#qrcode-${i}`)
              dom!.innerHTML = '';
              new Qrcode(dom, {
                text: WEB_HOST + '/device/index.html?d=' + this.deviceDataList[i]['deviceSn'],
                width: 100,
                height: 100
              })
            }
          })
        }, 0)
      }
    })
  }

  openAllocationDeviceNumDialog (row: IAgent) {
    this.deviceDataQuery.page = 1
    this.deviceDataQuery.limit = 10
    this.deviceDataQuery.id = row.id
    this.allocationDeviceDialog = true
    this.getDeviceDataList()
  }

  getReferringList () {
    this.referringDataLoading = true
    referringQueryApi(this.referringDataQuery).then(e => {
      if (e && e.data) {
        this.referringDataLoading = false
        this.referringDataTotal = e.data.total
        this.referringDataList = e.data.list
      }
    })
  }

  openReferringDialog (row: IAgent) {
    this.referringDataQuery.agentId = row.id
    this.referringDataQuery.page = 1
    this.referringDataQuery.limit = 10
    this.getReferringList()
    this.referringDialog = true
  }


  getCooperatingList () {
    this.cooperatingLoading = true
    cooperatingQueryApi(this.cooperatingQuery).then(e => {
      if (e && e.data) {
        this.cooperatingLoading = false
        this.cooperatingTotal = e.data.total
        this.cooperatingList = e.data.list
      }
    })
  }

  openCooperatingDialog (row: IAgent) {
    this.selectAgentId = row.id
    this.cooperatingQuery.agentId = row.id
    this.cooperatingQuery.page = 1
    this.cooperatingQuery.limit = 10
    this.getCooperatingList()
    this.cooperatingDialog = true
  }


  getCooperatingDeviceList () {
    this.cooperatingDeviceLoading = true
    cooperatingDeviceQueryApi(this.cooperatingDeviceQuery).then(e => {
      if (e && e.data) {
        this.cooperatingDeviceLoading = false
        this.cooperatingDeviceTotal = e.data.total
        this.cooperatingDeviceList = e.data.list
      }
    })
  }

  openCooperatingDeviceDialog (row: IAgent) {
    this.cooperatingDeviceQuery.agentId = this.selectAgentId
    this.cooperatingDeviceQuery.partnerId = row.id
    this.cooperatingDeviceQuery.page = 1
    this.cooperatingDeviceQuery.limit = 10
    this.getCooperatingDeviceList()
    this.cooperatingDeviceDialog = true
  }

  created () {
    this.getList()
  }
}
